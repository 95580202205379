<template>
  <div>
    <b-field :label="label" label-for="email" :type="{ 'is-danger': message.length > 0 }" :message="message">
      <b-input
        v-model="emailData"
        name="email"
        type="text"
        @input="$emit('update:email', emailData)"
        @focus="$emit('focus')"
      ></b-input>
    </b-field>
    <div
      class="overflow-hidden transition-all"
      :class="{ 'h-8': emailDomainSuggestions.length > 0, 'h-0': emailDomainSuggestions.length === 0 }"
    >
      <div class="flex gap-3 h-16 overflow-x-auto overflow-y-hidden flex-nowrap items-start">
        <div
          v-for="(suggestion, s) in emailDomainSuggestions"
          :key="'email_suggestion' + s"
          class="bg-gray-50 shadow border border-gray-200 hover:shadow-sm mt-0 hover:mt-0.5 rounded px-2 py-0.5 text-sm flex-shrink-0 cursor-pointer"
          @click="applySugestion(suggestion)"
        >
          {{ '@' + suggestion }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import config from '@/fw-modules/fw-core-vue/config'
export default {
  name: 'EmailInput',

  props: {
    email: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'E-mail'
    },
    message: {
      type: String,
      default: ''
    },
    onlyInstitutional: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      emailData: ''
    }
  },

  computed: {
    emailService() {
      let emailDomain = this.emailData.toLowerCase().split('@')
      if (emailDomain.length <= 1) {
        return 'none'
      }
      emailDomain = emailDomain[1]
      if (emailDomain.endsWith('uc.pt')) {
        return 'webmail UC'
      } else if (emailDomain.endsWith('gmail.com')) {
        return 'Gmail'
      } else if (emailDomain.endsWith('hotmail.com') || emailDomain.endsWith('outlook.com')) {
        return 'Outlook'
      } else {
        return emailDomain
      }
    },

    emailDomainSuggestions() {
      let emailDomain = this.emailData.toLowerCase().split('@')
      if (emailDomain.length <= 1) {
        return []
      }
      emailDomain = emailDomain[1]
      let domains = this.onlyInstitutional ? config.institutionalDomains : ServiceAuth.helpers.domainSuggestions
      let result = domains.filter(el => el.startsWith(emailDomain)).slice(0, 5)
      if (result.length > 0) {
        return result[0] === emailDomain ? [] : result
      } else {
        return []
      }
    }
  },

  created() {
    this.emailData = this.email
  },

  methods: {
    applySugestion(suggestion) {
      this.emailData = this.emailData.split('@')[0] + '@' + suggestion
      this.$emit('update:email', this.emailData)
      this.emailDomainSuggestions
    }
  }
}
</script>

<i18n>
{
  "en": {
    "email": "Email",
    "emailPlaceholder": "Email address"
  },
  "pt": {
    "email": "Email",
    "emailPlaceholder": "Indique um email"
  }
}
</i18n>
